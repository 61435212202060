// React components
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

// Local components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Styles
import styles from "../sass/pages/portfolio.module.scss"

let lang = ""

const Portfolio = () => {
  lang = useIntl().locale

  const title = useIntl().formatMessage({ id: "portfolio" })
  const portfolioMetaDescription = useIntl().formatMessage({
    id: "portfolio_meta_description",
  })

  return (
    <Layout color="">
      <SEO
        lang={lang}
        title={title}
        description={portfolioMetaDescription}
      />
      <article className="container-small container-wide-mobile">
        <h1 className={styles.space + " " + styles.spaceMobile}>
          <FormattedMessage id="home_projects_title" />
        </h1>
        <Link to={`/${lang}` + "/voy-de-viaje"}>
          <div className={"cardWide " + styles.voyDeViaje}>
            <div className="cardContentLarge">
              <p className="cardHashtags"></p>
              <p className="cardTitleLarge">
                <FormattedMessage id="first_project_title" />
              </p>
            </div>
          </div>
        </Link>
        <div className={"grid-cards-2-1 " + styles.bottomMargin}>
          <Link to={`/${lang}` + "/movie"}>
            <div className={"portfolioCard " + styles.movie}>
              <div className="cardContent">
                <p className="cardHashtags"></p>
                <p className="cardTitle">
                  <FormattedMessage id="second_project_title" />
                </p>
              </div>
            </div>
          </Link>
          <Link to={`/${lang}` + "/oca"}>
            <div className={"portfolioCard " + styles.oca}>
              <div className="cardContent">
                <p className="cardHashtags"></p>
                <p className="cardTitle">
                  <FormattedMessage id="third_project_title" />
                </p>
              </div>
            </div>
          </Link>
        </div>
      </article>
    </Layout>
  )
}

export default Portfolio
